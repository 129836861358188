export type BaseNotification = {
  id: string;
  title: string;
  description: string;
  read: boolean;
  type: NotificationType;
  timestamp: Date;
};

export type Notification = BaseNotification & {
  type: NotificationType.INFO | NotificationType.SUCCESS | NotificationType.WARNING;
};

export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export enum SupportedLanguages {
  ENGLISH = 'en',
}

export type NotificationDescription = {
  language: SupportedLanguages;
  description: string;
};

export type ErrorMessageResponse = {
  code: string;
  errorType: string;
  system: string;
  context: string | null;
  descriptions: NotificationDescription[];
};

export interface ErrorNotification extends BaseNotification {
  type: NotificationType.ERROR;
  code?: string;
  severity: 'ERROR';
  readMoreUrl: string | null;
}

